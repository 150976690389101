import { InputLabel, Select, FormControl, Box } from "@material-ui/core";
import React from "react";

const DropdownComponent = ({
  value,
  handleChange,
  options,
  labelText,
  id,
  disable,
}) => {
  return (
    <Box className="d-flex form-group">
      <FormControl className="col-12 main-dropdown" variant="outlined">
        <InputLabel id={id + "_dropdown"}>{labelText}</InputLabel>
        <Select
          disabled={disable ?? false}
          native
          id={id + "_selected"}
          value={value}
          label={labelText}
          onChange={handleChange}
        >
          {options.map((option) => (
            <option key={option.type} value={option.type}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownComponent;
