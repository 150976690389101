import { AppBar, Box, Button, CircularProgress, Dialog, FormControl, FormHelperText, IconButton, InputLabel, Paper, Select, TextField, Toolbar, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import DelayedRedirect from "../../../../utils/DelayedRedirect";
import { Transition } from "../../../../utils/ModalHelpers";
import Close from "@material-ui/icons/Close";
import { LanguagesWithLanguageCode } from "../../../../utils/constants";
import { DisplayMessage, Uuidv4 } from "../../../../services/helpers";
import { DropzoneArea } from "material-ui-dropzone";
import { PictureAsPdf } from "@material-ui/icons";
import { createDisclaimer } from "./services/disclaimersApisService";
import { CheckIfSasIsValid, GenerateSAS, UploadFileInStorage } from "../../../../services/uploadHelpers";

export default function DisclaimersCreation(props) {
    const { resources, accessibleMoboOutlets, outletId, language } = props;
    const [redirect, setRedirect] = useState(null);
    const [disclaimerWasCreated, setDisclaimerWasCreated] = useState(false);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(true);
    const [selectedOutlet, setSelectedOutlet] = useState(outletId);
    const [selectedType, setSelectedType] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const [disclaimerDescription, setDisclaimerDescription] = useState("");
    const [disclaimerPdf, setDisclaimerPdf] = useState(undefined);

    const [requestIsValid, setRequestIsValid] = useState(false);

    var dropzoneClassCustom = disclaimerPdf ? "dropzone-hidden" : `dropzone large ${disclaimerDescription ? "disabled" : ""}`;

    const handleClose = () => {
        setDialogOpen(false);
        setRedirect("/disclaimers");
    }

    const uploadFile = async (file, body, error) => {
        const sas = await GenerateSAS(body);
        if (!CheckIfSasIsValid(sas)) {
            DisplayMessage(resources["ErrorUploadingFileToStorage"], "error");
            return undefined;
        }
        var blobPath = await UploadFileInStorage(file, sas[0], "application/pdf", error);
        return blobPath;
    }

    const handleSubmit = async () => {
        if (!isRequestValid(true)) return;

        setRequestInProgress(true);

        var body = {};
        if (disclaimerDescription.trim().length > 0) {
            body = JSON.stringify({
                moboOutletId: selectedOutlet,
                type: selectedType,
                language: selectedLanguage,
                disclaimer: disclaimerDescription
            });
        }
        else {
            var sasRequest = JSON.stringify({
                minutes: 5,
                entityType: "disclaimer",
                entityValue: selectedOutlet,
                fileNames: [`${Uuidv4()}.pdf`],
            });

            var blobPath = await uploadFile(disclaimerPdf, sasRequest, resources["ErrorOnLoadingFile"]);
            if (blobPath === undefined) {
                setRequestInProgress(false);
                return;
            }
            body = JSON.stringify({
                moboOutletId: selectedOutlet,
                type: selectedType,
                language: selectedLanguage,
                file: {
                    blobRelativePath: blobPath,
                    type: 4
                }
            });
        }
        var result = await createDisclaimer(body);
        if (result !== undefined) {
            setDisclaimerWasCreated(true);
            DisplayMessage(resources["DisclaimerWasCreatedWithSuccess"], "success");
        }
        setRequestInProgress(false);
        setRedirect("/disclaimers");
    }

    const isRequestValid = (displayError) => {
        var valid = true;

        if (selectedType < 1 || selectedType > 4) {
            valid = false;
            if (displayError) DisplayMessage(resources["InvalidType"], 'error');
        }

        if (disclaimerDescription.trim().length === 0 && disclaimerPdf === undefined) {
            valid = false;
            if (displayError) DisplayMessage(resources["InvalidDisclaimer"], 'error');
        }

        if (selectedOutlet === 0) {
            valid = false;
            if (displayError) DisplayMessage(resources["InvalidOutlet"], 'error');
        }

        setRequestIsValid(valid);
        return valid;
    }

    useEffect(() => {
        isRequestValid(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType, selectedOutlet, disclaimerDescription, disclaimerPdf])

    const generateOutletsOptions = () => {
        if (accessibleMoboOutlets === undefined) return;
        var outlets = accessibleMoboOutlets.sort((a, b) => (a.description > b.description) ? 1 : -1)

        var outletOptions = outlets.map(x => <option key={x.id} value={x.id}> {x.description}</option>);
        return outletOptions;
    }

    const handleChangeOutlet = (event) => {
        setSelectedOutlet(parseInt(event.target.value));
        isRequestValid(false);
    }

    const generateLanguageOptions = () => {
        var languageOptions = LanguagesWithLanguageCode.map(x => <option key={x.code} value={x.code}>{x.Name}</option>);

        return languageOptions;
    }

    const handleChangeLanguage = (event) => {
        setSelectedLanguage(event.target.value);
    }

    const generateTypeOptions = () => {
        const types = [
            { name: resources["DisclaimerType1"], type: 1 },
            { name: resources["DisclaimerType2"], type: 2 },
            { name: resources["DisclaimerType3"], type: 3 },
            { name: resources["DisclaimerType4"], type: 4 },
        ];

        var typeOptions = types.map(x => <option key={x.type} value={x.type}>{x.name}</option>);
        return typeOptions;
    }

    const handleChangeType = (event) => {
        setSelectedType(parseInt(event.target.value));
    }

    const handleChangeDescription = (event) => {
        setDisclaimerDescription(event.target.value);
    }

    const handlePreviewIcon = (fileObject, classes) => {
        const iconProps = {
            className: classes.image,
        }
        return <PictureAsPdf {...iconProps} />
    }

    const handleFileChange = (files) => {
        var firstFile = files.length > 0 ? files[0] : undefined;
        setDisclaimerPdf(firstFile);
    }

    return (
        <Box>
            {redirect && <DelayedRedirect to={{ pathname: redirect, state: { outletId, refreshGrid: disclaimerWasCreated } }} delay={250} />}
            <Dialog fullScreen open={dialogOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className="position-sticky">
                    <Toolbar>
                        <IconButton id="close-new-newDisclaimer" edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            {resources["NewDisclaimer"]}
                        </Typography>
                        <Button id="disclaimer-save" color="inherit" disabled={requestInProgress || !requestIsValid} onClick={handleSubmit}>{resources["Save"]}</Button>
                    </Toolbar>
                </AppBar>
                <Box className={"centered-loader " + (requestInProgress ? 'fadeIn' : 'fadeOut')}><CircularProgress /></Box>
                <Box className={"container " + (requestInProgress ? 'fadeOut' : 'fadeIn')}>
                    <Box className="py-4 grey">
                        <Paper elevation={3} className="p-4 mt-3 mb-3">
                            <Box>
                                <Box className="d-flex form-group">
                                    <div className="col-4"></div>
                                    <FormControl className="col-4" variant="outlined">
                                        <InputLabel id="outlet_dropdown">{resources["Outlet"]}</InputLabel>
                                        <Select
                                            native
                                            id="outlet_selected"
                                            value={selectedOutlet}
                                            label={resources["Outlet"]}
                                            onChange={handleChangeOutlet}
                                        >
                                            <option value={0}>{resources["Select"]}</option>
                                            {generateOutletsOptions()}
                                        </Select>
                                        <FormHelperText error={selectedOutlet === 0} className="helper">{resources["SelectionOfOutletIsMandatory"]}</FormHelperText>
                                    </FormControl>
                                    <div className="col-4"></div>
                                </Box>

                                <Box className="d-flex form-group">
                                    <div className="col-4"></div>
                                    <FormControl className="col-4 main-dropdown" variant="outlined">
                                        <InputLabel id="type_dropdown">{resources["Type"]}</InputLabel>
                                        <Select
                                            native
                                            id="type_selected"
                                            value={selectedType}
                                            label={resources["Type"]}
                                            onChange={handleChangeType}
                                        >
                                            <option value={0}>{resources["Select"]}</option>
                                            {generateTypeOptions()}
                                        </Select>
                                        <FormHelperText error={selectedType === 0} >{resources["SelectionOfTypeIsMandatory"]}</FormHelperText>
                                    </FormControl>
                                    <div className="col-4"></div>
                                </Box>

                                <Box className="d-flex form-group">
                                    <div className="col-4"></div>
                                    <FormControl className="col-4" variant="outlined" >
                                        <InputLabel id="language_dropdown">{resources["Language"]}</InputLabel>
                                        <Select
                                            native
                                            id="language_selected"
                                            value={selectedLanguage}
                                            label={resources["Language"]}
                                            onChange={handleChangeLanguage}
                                        >
                                            {generateLanguageOptions()}
                                        </Select>
                                    </FormControl>
                                    <div className="col-4"></div>
                                </Box>

                                <Box className="d-flex form-group">
                                    <div className="col-4"></div>
                                    <FormControl className="col-4" variant="outlined">
                                        <FormHelperText error={!disclaimerDescription && !disclaimerPdf} style={{ marginBottom: "16px" }}>
                                            {resources["DisclaimerOrPdfFileIsMandatory"]}
                                        </FormHelperText>
                                        <TextField
                                            id="disclaimers-description"
                                            value={disclaimerDescription}
                                            label={resources["Disclaimer"]}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="description"
                                            onChange={handleChangeDescription}
                                            disabled={disclaimerPdf}
                                            multiline
                                            minRows={5} />
                                    </FormControl>
                                    <div className="col-4"></div>
                                </Box>

                                <Box className="d-flex form-group" style={{ justifyContent: "center" }}>
                                    <Box style={{ minWidth: "350px" }}>
                                        <FormHelperText style={{ marginLeft: 16, marginTop: 0, marginBottom: disclaimerPdf ? 16 : 0 }}>{resources["PDFFile"]}</FormHelperText>
                                        <DropzoneArea
                                            className="col-4"
                                            acceptedFiles={['.pdf']}
                                            filesLimit={1}
                                            showAlerts={false}
                                            onAlert={(message, variant) => variant === 'error'
                                                ? DisplayMessage(message, variant)
                                                : undefined}
                                            showPreviews={true}
                                            previewText=''
                                            getPreviewIcon={handlePreviewIcon}
                                            dropzoneClass={dropzoneClassCustom}
                                            dropzoneParagraphClass={"dropzone-p"}
                                            maxFileSize={30000000}
                                            dropzoneText={resources["DropPdfFileOrClickToSelectFile"]}
                                            onChange={handleFileChange}
                                            dropzoneProps={{ disabled: disclaimerDescription }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Dialog >
        </Box >)
}